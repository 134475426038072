import React, { Component } from 'react';
import axios from 'axios';

class Users extends Component {
    constructor(props) {
        super(props)

        this.state = {
            users: []
        }
    }

    componentDidMount() {
        axios('https://jsonplaceholder.typicode.com/users')
        .then(response => {
            this.setState({
                users: response.data
            })
            console.log(response.data)
        })
    }

    render() {
        const {users} = this.state
        return (
            <div>
                <h2>Users</h2><br />
                {
                    users.map(user =>
                        <dl key={user.id} className="row">
                            <dt className="col-sm-3">Username</dt>
                            <dd className="col-sm-9">{user.username}</dd>

                            <dt className="col-sm-3">Name</dt>
                            <dd className="col-sm-9">{user.name}</dd>

                            <dt className="col-sm-3">E-mail</dt>
                            <dd className="col-sm-9">{user.email}</dd>

                            <br /><br />
                        </dl>
                    )
                }
            </div>
        )
    }
}

export default Users;