import React, { Component } from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import HouseIcon from 'react-bootstrap-icons/dist/icons/house-fill';
import SlidersIcon from 'react-bootstrap-icons/dist/icons/sliders';
import PeopleIcon from 'react-bootstrap-icons/dist/icons/people-fill';
import LinkIcon from 'react-bootstrap-icons/dist/icons/link-45deg';
import ImagesIcon from 'react-bootstrap-icons/dist/icons/images';
import JoystickIcon from 'react-bootstrap-icons/dist/icons/joystick';
import InfoIcon from 'react-bootstrap-icons/dist/icons/info-lg';
import GearIcon from 'react-bootstrap-icons/dist/icons/gear-fill';
import BoxArrowInRightIcon from 'react-bootstrap-icons/dist/icons/box-arrow-in-right';
import Users from './components/Users';
// import BoxArrowOutRightIcon from 'react-bootstrap-icons/dist/icons/box-arrow-right';

class App extends Component {
  render() {
    return (
      // <div className="App">
      <>
        <Router>
          <Navbar bg='dark' variant='dark' expand='lg' sticky='top'>
            <Container>
                <Navbar.Brand as={Link} to='/'>
                  <img
                    src={logo}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    alt="illanes.com logo"
                  />{' '}
                  illanes.com
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    <Nav.Link as={Link} to="/home"><HouseIcon /></Nav.Link>
                    <NavDropdown title={<SlidersIcon />} id="basic-nav-dropdown">
                      <NavDropdown.Item as={Link} to="/links"><LinkIcon /> Links</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/images"><ImagesIcon /> Images</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/games"><JoystickIcon /> Games</NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item as={Link} to="/users"><PeopleIcon /> Users</NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                  <Nav className="ml-auto">
                    <NavDropdown title={<GearIcon />} id="basic-nav-dropdown">
                      <NavDropdown.Item as={Link} to="/sign_in"><BoxArrowInRightIcon /> Log in</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/info"><InfoIcon /> About</NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </Navbar.Collapse>
            </Container>
          </Navbar>
          <header className="App-header">
            <Container>
              <Switch>
                <Route path="/home">
                  <h2>Home</h2>
                </Route>
                <Route path="/links">
                  <h2>Links</h2>
                </Route>
                <Route path="/images">
                  <h2>Images</h2>
                </Route>
                <Route path="/games">
                  <h2>Games</h2>
                </Route>
                <Route path="/users">
                  <Users />
                </Route>
                <Route path="/info">
                  <h2>Info</h2>
                </Route>
                <Route path="/">
                  <img src={logo} className="App-logo" alt="logo" />
                  <p>
                    Page <code>illanes.com</code> is under construction.
                  </p>
                </Route>
              </Switch>
            </Container>
          </header>
        </Router>
      </>
    );
  }
}

export default App;
